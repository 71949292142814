import { useGetNewsAlertsQuery, useUpdateNewsPrefsMutation } from "../../store/settingApi";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from "primereact/dialog";
import { useDispatch, useSelector } from "react-redux";
import { setSettingsModal, settingsModalState } from "../../store/commonSlice";
import { formatDateDDMMMMYYYY } from "../../utils/utility-function";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { useState } from "react";
import { toast } from "react-toastify";

const NewsAlertsDialog = () => {

    const { data = [] } = useGetNewsAlertsQuery();
    const [updateNewsPrefsCall, { isLoading }] = useUpdateNewsPrefsMutation();
    const dispatch = useDispatch();
    const serviceModal = useSelector(settingsModalState);

    const [skipAlert, setSkipAlert] = useState(false);

    const createDynamicTabs = () => {
        return data.map((tab, i) => {
            return (
                <AccordionTab key={tab.title} header={
                    <span className="flex align-items-center gap-2 w-full">
                        <span className="font-bold ml-auto">{formatDateDDMMMMYYYY(tab.start_date)}: </span>
                        <h2 className="font-bold white-space-nowrap" style={{ fontSize: '1.3em' }}>{tab.title}</h2>
                    </span>
                }>
                    <div className="custom-description" dangerouslySetInnerHTML={{ __html: tab.description }} />
                </AccordionTab>
            );
        });
    };

    const updateNewsPrefs = async () => {
        console.log(skipAlert);
        if (skipAlert) {
            await updateNewsPrefsCall().unwrap()
                .then((payoad: any) => {
                    toast.success('News Alert Skipped Successfully');
                    setSkipAlert(false);
                })
                .catch((e: any) => {
                    toast.error(e.data.message);
                });
        }
        dispatch(setSettingsModal({ newsAlertsModal: false }));
    }

    return (
        <Dialog
            header="Latest Updates"
            visible={data.length !== 0 && serviceModal.newsAlertsModal}
            style={{ width: '55vw' }}
            onHide={() => {
                dispatch(setSettingsModal({ newsAlertsModal: false }));
            }}
        >
            <Accordion>{createDynamicTabs()}</Accordion>
            <div className="flex col-12 justify-content-end">
                <div className="flex align-items-center mr-4">
                    <Checkbox inputId="alertPrefs" name="alertPrefsName" value="1" onChange={() => setSkipAlert(!skipAlert)} checked={skipAlert} />
                    <label htmlFor="alertPrefs" className="ml-2">Do not show this message again </label>
                </div>
                <div className={`field mt-3`}>
                    <Button
                        label="Confirm"
                        onClick={updateNewsPrefs}
                        icon="pi pi-save"
                        className="p-button-sm"
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default NewsAlertsDialog;