import { useSelector } from 'react-redux';
import {
	Navigate,
	RouteObject,
	useLocation,
	useRoutes,
} from 'react-router-dom';
import { useGetICAVersionQuery } from '../store/apiSlice';
import { getIsAdmin, getInvalidUser, getIsServiceOwner } from '../store/commonSlice';
import { useGetCurrentUserQuery } from '../store/UserApi';
import ActualsPage from '../views/actuals/actuals';
import ArchiveShellPage from '../views/archive';
import ArchivePage from '../views/archive/archive-page';
import AssetListUpload from '../views/asset-list-upload';
import AssetListShell from '../views/asset-list-upload/asset-list-shell';
import AssetSummaryList from '../views/asset-summary';
import AzureChargingReport from '../views/azure-charging-report';
import ChargingReportPivot from '../views/charging-report-pivot';
import ChargingReportUpload from '../views/charging-report-upload';
import ChargingReportShell from '../views/charging-report-upload/charging-report-shell';
import CostCentersPage from '../views/cost-center';
import DashboardPage from '../views/dashboard';
import DataProcessing from '../views/data-processing';
import HomePage from '../views/home';
import ICAExport from '../views/ica-export';
import MaintenancePage from '../views/maintenance-page';
import MappingPage from '../views/mapping';
import MappingDifference from '../views/mapping/mapping-difference';
import MappingShell from '../views/mapping/mapping-shell';
import NewServices from '../views/new-services';
import ServicePage from '../views/service';
import SettingsPage from '../views/settings';
import UsersPage from '../views/users';
import WildCardMapping from '../views/wild-card-mapping';
import BugReportTrack from '../views/bug-report-track';
import ReleaseNotes from '../views/release-notes';
import InvalidUser from '../views/invalid-user';
import RequestServiceId from '../views/request-service-id';
import NewAssets from '../views/asset-list-upload/new-asset';
import CrossCharging from './../views/cross-charging/index';
import { getIs103User } from './../store/commonSlice';
import NewsAlerts from '../views/news-alerts';

function Routes() {
	const {
		data = [],
		isLoading,
		isSuccess,
		isError,
		error,
	} = useGetICAVersionQuery();
	const isAdmin = useSelector(getIsAdmin);
	const isServiceOwner = useSelector(getIsServiceOwner);
	const is103User = useSelector(getIs103User);
	const isInvalidUser = useSelector(getInvalidUser);
	const location = useLocation();
	const { data: currentUser, isLoading: isLoadingUser } =
		useGetCurrentUserQuery(0);

	const user98: RouteObject[] = [
		{
			path: '/',
			element: (
				<Navigate to="/actuals/itServices" state={{ prevPath: location.pathname }} />
			),
		},
		{
			path: '/actuals',
			element: (
				<Navigate to="/actuals/itServices" state={{ prevPath: location.pathname }} />
			),
		},
		{
			path: '/actuals/workplace',
			element: <ActualsPage />,
		},
		{
			path: '/actuals/itServices',
			element: <HomePage />,

			children: [
				{
					index: true,
					element: <ActualsPage />,
				},
				{
					path: ':costCenterId',
					element: <ServicePage />,
				},
			],
		},
	]

	const user103: RouteObject[] = [
		{
			path: '/',
			element: (
				<Navigate to="/actuals/itServices_103" state={{ prevPath: location.pathname }} />
			),
		},
		{
			path: '/actuals',
			element: (
				<Navigate to="/actuals/itServices_103" state={{ prevPath: location.pathname }} />
			),
		},
		{
			path: '/actuals/workplace_103',
			element: <ActualsPage />,
		},
		{
			path: '/actuals/itServices_103',
			element: <HomePage />,

			children: [
				{
					index: true,
					element: <ActualsPage />,
				},
				{
					path: ':costCenterId',
					element: <ServicePage />,
				},
			],
		},
	]

	let CommonRoutes: RouteObject[] = [
		// {
		// 	path: 'dashboard',
		// 	element: <DashboardPage />,
		// },
		{
			path: 'dataProcessing',
			element: <DataProcessing />,
			children: [
				{
					index: true,
					element: (
						<Navigate
							to="/dataProcessing/reportUpload"
							state={{ prevPath: location.pathname }}
						/>
					),
				},
				{
					path: 'chargingReportPivot',
					element: <ChargingReportPivot />,
				},
				{
					path: 'reportUpload',
					element: <ChargingReportShell />,
					children: [
						{
							path: '',
							element: <ChargingReportUpload />,
						},
					],
				},
				{
					path: 'requestServiceId',
					element: <RequestServiceId isEditable={false} />
				},
			],
		},
		{
			path: 'settings',
			element: <SettingsPage />,
			children: [
				{
					index: true,
					element: (
						<Navigate
							to="/settings/mapping"
							state={{ prevPath: location.pathname }}
						/>
					),
				},
				{
					path: 'mapping',
					element: <MappingShell />,
					children: [
						{
							path: '',
							element: <MappingPage />,
						},
						{
							path: 'mappingDifference',
							element: <MappingDifference />,
						},
					],
				},

				{
					path: 'costcenters',
					element: <CostCentersPage />,
				},
			],
		},
		{ path: '/maintenance', element: <MaintenancePage /> },
	];

	let AdminRoutes: RouteObject[] = [
		{
			path: '/',
			element: (
				<Navigate to="/actuals/itServices" state={{ prevPath: location.pathname }} />
			),
		},
		{
			path: '/actuals',
			element: (
				<Navigate to="/actuals/itServices" state={{ prevPath: location.pathname }} />
			),
		},
		{
			path: '/actuals/workplace',
			element: <ActualsPage />,
		},
		{
			path: '/actuals/itServices',
			element: <HomePage />,

			children: [
				{
					index: true,
					element: <ActualsPage />,
				},
				{
					path: ':costCenterId',
					element: <ServicePage />,
				},
			],
		},
		{
			path: '/actuals/workplace_103',
			element: <ActualsPage />,
		},
		{
			path: '/actuals/itServices_103',
			element: <HomePage />,

			children: [
				{
					index: true,
					element: <ActualsPage />,
				},
				{
					path: ':costCenterId',
					element: <ServicePage />,
				},
			],
		},
		// {
		// 	path: 'dashboard',
		// 	element: <DashboardPage />,
		// },
		{
			path: 'dataProcessing',
			element: <DataProcessing />,
			children: [
				{
					index: true,
					element: (
						<Navigate
							to="/dataProcessing/reportUpload"
							state={{ prevPath: location.pathname }}
						/>
					),
				},
				{
					path: 'chargingReportPivot',
					element: <ChargingReportPivot />,
				},
				{
					path: 'reportUpload',
					element: <ChargingReportShell />,
					children: [
						{
							path: '',
							element: <ChargingReportUpload />,
						},
						{
							path: 'wildCard',
							element: <WildCardMapping />,
						},
						{
							path: 'newServices',
							element: <NewServices />,
						},
					],
				},

				{
					path: 'reportUploadMBAG',
					element: <ChargingReportShell />,
					children: [
						{
							path: '',
							element: <ChargingReportUpload />,
						},
						{
							path: 'wildCard',
							element: <WildCardMapping />,
						},
						{
							path: 'newServices',
							element: <NewServices />,
						},
					],
				},
				{
					path: 'crossCharging',
					element: <CrossCharging />
				},
				{
					path: 'requestServiceId',
					element: <RequestServiceId isEditable={false} />
				},
				{
					path: 'assetListUpload',
					element: <AssetListShell />,
					children: [
						{
							path: '',
							element: <AssetListUpload />,
						},
						{
							path: 'assetSummary',
							element: <AssetSummaryList />,
						},
						{
							path: 'newAssets',
							element: <NewAssets />,
						},
					],
				},
				{
					path: 'assetNewListUpload',
					element: <AssetListShell />,
					children: [
						{
							path: '',
							element: <AssetListUpload />,
						},
						{
							path: 'assetSummary',
							element: <AssetSummaryList />,
						},
					],
				},
				{
					path: 'cloudCostsChargingReport',
					element: <AzureChargingReport />,
				},
				{
					path: 'ICAExport',
					element: <ICAExport />,
				},
			],
		},
		{
			path: 'settings',
			element: <SettingsPage />,
			children: [
				{
					index: true,
					element: (
						<Navigate
							to="/settings/mapping"
							state={{ prevPath: location.pathname }}
						/>
					),
				},
				{
					path: 'mapping',
					element: <MappingShell />,
					children: [
						{
							path: '',
							element: <MappingPage />,
						},
						{
							path: 'mappingDifference',
							element: <MappingDifference />,
						},
					],
				},

				{
					path: 'costcenters',
					element: <CostCentersPage />,
				},
				{
					path: 'requestServiceId',
					element: <RequestServiceId />,
				},
				{
					path: 'users',
					element: <UsersPage />,
				},
				{
					path: 'newsAlerts',
					element: <NewsAlerts />,
				},
			],
		},
		{
			path: '/maintenance',
			element: (
				<Navigate to="/actuals" state={{ prevPath: location.pathname }} />
			),
		},
	];

	const archiveroutes = {
		path: 'archive',
		element: <ArchiveShellPage />,
		children: [
			{
				index: true,
				element: (
					<Navigate
						to={`/archive/${data.length > 0 ? data[0].id : 0}`}
						state={{ prevPath: location.pathname }}
					/>
				),
			},
			{
				path: ':versionId',
				element: <ActualsPage />,
			},
			{
				path: ':versionId/:costCenterId',
				element: <ServicePage />,
			},
		],
	};

	// let UserRoutes: RouteObject[] = user103.concat(CommonRoutes)
	let UserRoutes: RouteObject[] = is103User ? [...user103, ...CommonRoutes] : [...user98, ...CommonRoutes]

	let ServiceRoutes: RouteObject[] = UserRoutes.map((obj: any) => {
		if (isServiceOwner && obj.path === 'dataProcessing') {
			obj.children = []
			obj.children.unshift({
				path: 'crossCharging',
				element: <CrossCharging />
			})
			obj.children.push({
				path: 'cloudCostsChargingReport',
				element: <AzureChargingReport />,
			})
			obj.children.push({
				path: 'ICAExport',
				element: <ICAExport />,
			})
		}
		if (isServiceOwner && obj.path === 'settings') {
			obj.children.push({
				path: 'requestServiceId',
				element: <RequestServiceId />,
			})
		}
		return obj;
	})

	const bugTrackRoute = {
		path: 'bugTrack',
		element: <BugReportTrack />,
	};

	const releaseRoute = {
		path: 'releaseNotes',
		element: <ReleaseNotes />,
	};

	let invalidUserRoute: RouteObject[] = [
		{
			path: '/',
			element: (
				<Navigate to="/invalidUser" state={{ prevPath: location.pathname }} />
			),
		},
		{
			path: 'invalidUser',
			element: <InvalidUser />,
		},
		{
			path: '*',
			element: <Navigate to="/invalidUser" state={{ prevPath: location.pathname }} />
		}
	];

	let routes = isAdmin ? AdminRoutes : isServiceOwner ? ServiceRoutes : UserRoutes;

	routes.push(archiveroutes);

	// routes.push(bugTrackRoute);

	routes.push(releaseRoute);

	// routes.push(userInvalidRoute);

	let validRoute = isInvalidUser ? invalidUserRoute : routes;

	return useRoutes(validRoute);
}

export default Routes;
