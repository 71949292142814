import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useMemo, useState } from "react";
import { useGetAllServiceIDQuery, useUpdateServiceIDMutation } from "../../store/settingApi";
import { formatDateDDMMYYYY } from "../../utils/utility-function";
import { getSearchTerm, setSettingsModal } from '../../store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import DisplayIcon from "../../components/icon";
import AddServiceID from "./add-service-id-deialog";
import { toast } from "react-toastify";
import { AutoComplete } from 'primereact/autocomplete';
import { useGetAllUsersQuery } from "../../store/UserApi";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

interface RequestServiceIdProps {
	isEditable?: boolean;
}

const RequestServiceId = ({ isEditable = true }: RequestServiceIdProps ) => {

	const dispatch = useDispatch();
	
    const statusList = [
        { status: "Active", value: "Active" },
        { status: "InActive", value: "InActive" },
    ]

	useEffect(() => {
		document.title = 'Request Service ID | XCharge';
	}, []);

	const { data, isLoading } = useGetAllServiceIDQuery();
	const [updateServiceID, { isLoading: isUpdating }] = useUpdateServiceIDMutation();

	const searchTerm = useSelector(getSearchTerm);

	const [searchKeyword, setSearchKeyword] = useState('');
	const [editService, setEditService] = useState<any>(undefined);

	useEffect(() => {
		const apiCallTimeoutId = setTimeout(() => {
			if (searchTerm !== '') {
				setSearchKeyword(searchTerm);
			} else {
				setSearchKeyword('');
			}
		}, 1000);

		return () => clearTimeout(apiCallTimeoutId);
	}, [searchTerm]);

	const computedData = useMemo(() => {
		let result = data;

		result =
			searchKeyword !== '' && data
				? data.filter((items: any) => {
					console.log(Object.values(items));
					return (
						Object.values(items).findIndex((chunk: any) =>
							chunk
								.toString()
								.toLowerCase()
								.includes(searchKeyword.toLowerCase())
						) !== -1
					);
				})
				: data;

		return result;
	}, [data, searchKeyword]);

	const header = () => {
		return (
			<div className="flex align-items-center justify-content-between">
				<div className="flex align-items-center">
					<h5>Request Service ID</h5>
				</div>
				<div className="actions flex">
					{isEditable && <Button
						icon="pi pi-filter"
						className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
						tooltip="Request Service ID"
						tooltipOptions={{ position: 'left' }}
						onClick={() => {
							dispatch(setSettingsModal({ addServiceID: true }));
						}}
					>
						<DisplayIcon icon="add" className="action-icon" />
					</Button>}
				</div>
			</div>
		);
	};

	const actions = (rowData: any) => {
		return (
			<div className="table-menu">
				{
					editService !== undefined && rowData.id === editService.id ? (
						<>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								loading={isUpdating}
								onClick={async () => {
									// setModalType('ICA');
									console.log(editService);
									if (editService.status !== rowData.status && editService.serviceOwner || editService.serviceOwner !== rowData.serviceOwner || editService.description !== rowData.description) {
										await updateServiceID({
												id: editService.id,
												serviceName: editService.serviceName,
												serviceId: editService.serviceId,
												serviceOwner: editService.serviceOwner,
												serviceOwnerEmail: editService.serviceOwnerEmail,
												description: editService.description,
												status: editService.status
											})
											.unwrap()
											.then((payload: any) => {
												toast.success('Service Updated Successfully')
												setEditService(undefined);
											})
											.catch((error: any) => toast.error(error.data.message));
									}
								}}
								tooltip="Save Changes"
							>
								<DisplayIcon icon="tablerSave" className="table-action" />
							</Button>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								onClick={() => {
									setEditService(undefined);
								}}
								tooltip="Cancel"
							>
								<DisplayIcon icon="tablerCancel" className="table-action" />
							</Button>

						</>
					) : (
						<>
							<Button
								className="p-button-rounded p-button-text p-button-icon-only"
								tooltipOptions={{ position: 'left' }}
								onClick={() => {
									// setModalType('ICA');
									console.log(rowData);
									setEditService(rowData);
								}}
								tooltip="Edit Service"
							>
								<DisplayIcon icon="edit" className="table-action" />
							</Button>
						</>
					)
				}
			</div>
		);
	};

	const itemTemplate = (item: any) => {
		return (
			<div className="country-item">
				<div>{item.name}</div>
			</div>
		);
	};
	
	const { data: allUsers = [] } = useGetAllUsersQuery();

    const [ownerData, setOwnerData] = useState<any>(undefined);
    const [filteredValues, setFilteredValues] = useState<any>(null);

    const searchValues = (event: any) => {
        setTimeout(() => {
            let _filteredValues;
            let inputList = allUsers.map((user) => ({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                firstName: user.firstName,
                email: user.email,
            }))
            if (!event.query.trim().length) {
                _filteredValues = [...inputList];
            } else {
                _filteredValues = inputList.filter((listValue) => {
                    return listValue.name.toLowerCase().includes(event.query.toLowerCase());
                }
                );
            }
            setFilteredValues(_filteredValues);
        }, 100);
    };

	
	const statusBody = (rowData: any) => {
		if (editService !== undefined && rowData.id === editService.id) {
			return (
				<Dropdown
					value={editService.status}
					options={statusList}
					onChange={(item: any) => {
						console.log(item)
						setEditService({ ...editService, status: item.value });
						console.log(editService)
					}}
					optionLabel="status"
					id="Status"
					name="Status"
				/>
			)
		} else {
		return rowData.status;
		}
	}

	const descriptionBody = (rowData: any) => {
		if (editService !== undefined && rowData.id === editService.id) {
			return (
				<InputText
					name={`description-${rowData.id}`}
					value={editService.description}
					onChange={(e) => {
						console.log(e.target.value);
						console.log(editService);
						setEditService({
							...editService,
							description: e.target.value
						});
					}}
				/>
			)
		} else {
		return rowData.description;
		}
	}

	const editOrDisplayOwner = (rowData: any) => {

		if (editService !== undefined && editService.id === rowData.id) {
			return (
				<AutoComplete
					className={`auto-complete animate-block`}
					itemTemplate={itemTemplate}
					value={editService.serviceOwner}
					field="name"
					forceSelection
					suggestions={filteredValues}
					completeMethod={searchValues}
					onChange={(e) => {
						if (e.value === null) {
							e.value = ''
						}
						console.log(e.value);
						if (e !== undefined && e.value) {
							setEditService({ 
								...editService,
								serviceOwner: e.value.name,
							})
						}
						// else {
						// 	setOwnerData(undefined);
						// }

					}}
					id='assign-deputy-auto'
					inputId='name'
					dropdown
				/>
			);
		} else {
			return rowData.serviceOwner
		}
	};

	return (
		<>
			<div className={`grid`}>
				<div className="cards">

					<DataTable
						tableClassName='bug-report-track'
						value={computedData}
						responsiveLayout="scroll"
						header={header}
						loading={isLoading}
					>
						<Column field="serviceId" header="Service ID" sortable></Column>
						<Column field="serviceName" header="Service Name" sortable></Column>
						<Column field="serviceOwner" header="Service Owner" sortable body={editOrDisplayOwner}></Column>
						<Column field="description" header="Description" sortable body={descriptionBody}></Column>
						<Column
							field='createdON'
							header="Created On"
							body={(rowData) => formatDateDDMMYYYY(rowData.createdON)}
							sortable
						></Column>
						<Column
							field='updatedON'
							header="Updated On"
							body={(rowData) => formatDateDDMMYYYY(rowData.updatedON)}
							sortable
						></Column>
						<Column field="status" header="Status" body={statusBody} sortable></Column>
						{isEditable ? (
                            <Column
                                body={actions}
                                align="left"
                                bodyStyle={{ width: '10%' }}
                            ></Column>
                        ) : (
                            <Column
                                body={actions}
                                align="left"
                                className="placeholder-column"
                            ></Column>
						)}
					</DataTable>
				</div>
			</div>
			<AddServiceID />
		</>
	)
}

export default RequestServiceId;
