import { QueryStatus } from '@reduxjs/toolkit/dist/query/react';
import './App.scss';
import Navigation from './components/navigation';
import { useAppSelector } from './hooks';
import Main from './router';
import commonSlice, {
	commonState,
	getIsAdmin,
	getToken,
	selectSidebar,
	setDevToken,
	setIsAdmin,
	setIsCostController,
	setIs103User,
	setMailers,
	setToken,
	getMailers,
	setServiceOptions,
	getServiceOptions,
	setInvalidUser,
	getInvalidUser,
	setIsServiceOwner,
	setSettingsModal,
	getTriggerIntro,
} from './store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
	BrowserRouter,
	Navigate,
	useNavigate,
	HashRouter,
} from 'react-router-dom';
import SearchBar from './components/search-bar';
import { useLocation, useParams } from 'react-router-dom';

import { ProgressBar } from 'primereact/progressbar';

import {
	useGetCurrentUserQuery,
	useGetDevTokenQuery,
	useGetTokenMutation,
	useLazyGetLoggedIntUserQuery,
} from './store/UserApi';
import { useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import PathDebugger from './components/path-debugger/pathdebugger';
import { current } from '@reduxjs/toolkit';
import { getCurrentUser } from './services';
import { useGetMaintenanceModeQuery } from './store/commonApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'primereact/button';
import DisplayIcon from './components/icon';
import ReleaseDialog from './views/release-notes/release-dialog';
import { useDeleteArchiveMutation } from './store/archiveApi';
import NewsAlertsDialog from './views/news-alerts/news-alerts-dialog';
import CustomJoyride from './components/joyride';

function App() {
	const [cookies, setCookie, removeCookie] = useCookies(['token']);
	const [fetchDevToken, setFetchDevToken] = useState(false);
	const dispatch = useDispatch();
	const isTokenLoaded = useSelector(getToken);
	const devTokenLoaded = useSelector(commonState).devToken;
	const [skip, setSkip] = useState(false);
	const [getTokenServer, { isLoading: isTokenLoading }] = useGetTokenMutation();
	const stateToken = useSelector(getToken);

	const [getLoggeInUser] = useLazyGetLoggedIntUserQuery();
	const [deleteOldArchieve] = useDeleteArchiveMutation();
	const [currentUser, setCurrentUser] = useState();
	const { data: isMaintenanceMode } = useGetMaintenanceModeQuery();
	const [maintenanceMode, setMaintenanceMode] = useState<any>();
	const openModal = useSelector(getServiceOptions);
	const [isRoleAssigned, setIsRoleAssigned] = useState(false);
	const [skipIntro, setSkipIntro] = useState(false);
	const [runJoyride, setRunJoyride] = useState(true);

	const triggerInfo = useSelector(getTriggerIntro);
	
	const showRelease = () => {
		const isshow = localStorage.getItem('isshow');
		if (isshow === null) {
			localStorage.setItem('isshow', '1');
			dispatch(setServiceOptions({ releaseNotes: true }));
		}
	}

	useEffect(() => {
		setMaintenanceMode(isMaintenanceMode === 'true' ? true : false);
	}, [isMaintenanceMode]);

	const isAdmin = useSelector(getIsAdmin);
	const isInvalidUser = useSelector(getInvalidUser);

	useEffect(() => {
		if (stateToken !== '') {
			if (stateToken !== cookies.token) {
				removeCookie('token');
				setCookie('token', stateToken, {
					expires: new Date(Date.now() + 86400 * 1000),
					sameSite: 'lax',
				});
			}
		}
	}, [stateToken]);

	useEffect(() => {
		document.title = 'Home | XCharge';
		const getTokenFromCode = async (code: string) => {
			let token = await getTokenServer(code).unwrap();
			dispatch(setToken(token));
			setCookie('token', token, {
				expires: new Date(Date.now() + 86400 * 1000),
				sameSite: 'lax',
			});
			showRelease();
			setSkip(true);
		};
		const getDevToken = async () => {
			let response = await fetch(`${process.env.REACT_APP_API_URL}/user/token`);
			dispatch(setDevToken(await response.json()));
			showRelease();
			setSkip(true);
		};
		if (
			['staging', 'integration', 'production'].includes(
				process.env.REACT_APP_ENV as string
			)
		) {
			console.log('COOKIES FOUND IN BROWSER ::', cookies.token);
			if (cookies.token) {
				dispatch(setToken(cookies?.token));
				console.log(':: DISPATCH TOKEN ::', cookies.token);
			} else {
				if (window.location.href.indexOf('code') !== -1) {
					console.log(':: GOT CODE ::');
					let code = window.location.href.split('=')[1];
					code = code.split('#/')[0];
					var replaceURl = '';

					if (window.location.port !== '' && window.location.pathname !== '/') {
						replaceURl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
					} else {
						replaceURl = 'https://' + window.location.hostname;
					}
					// eslint-disable-next-line no-restricted-globals
					history.pushState(null, '', replaceURl);
					getTokenFromCode(code);
				} else {
					// eslint-disable-next-line no-restricted-globals
					location.href = process.env.REACT_APP_SSO_URL as string;
				}
			}
		} else {
			getDevToken();
		}
	}, []);
	const sidebar = useSelector(selectSidebar);
	const mailers = useSelector(getMailers);
	const isLoading = useAppSelector((state) => {
		return Object.values(state.api.queries).some((query) => {
			return query && query.status === QueryStatus.pending;
		});
	});
	useEffect(() => {
		const getUser = async () => {
			// const { data } = await getLoggeInUser()
			// if (data) {
			// 	dispatch(setIsAdmin(data.role.isSuperAdmin || data.role.isAdmin));
			// 	dispatch(setIsCostController(data.role.isCostController));
			// }
			await getLoggeInUser()
			.unwrap()
			.then((data: any) => {
				dispatch(setIsAdmin(data.role.isSuperAdmin || data.role.isAdmin));
				dispatch(setIsServiceOwner(data.role.isServiceOwner));
				dispatch(setIsCostController(data.role.isCostController));
				dispatch(setIs103User(data.role.isUser103))
				dispatch(setInvalidUser(false))
				setIsRoleAssigned(true);
				setSkipIntro(data.isNotViewOnBoarding);
			})
			.catch((err: any) => {
				console.log(err.data, "current user could not be fetched!")
				if(err.data.message && err.data.message.includes("No Valid Role in Alice")){
					dispatch(setInvalidUser(true))
					setIsRoleAssigned(true);
				}
			})
		};
		getUser();
	}, [skip]);

	useEffect(() => {
		const deleteArchieve = async () => {
			await deleteOldArchieve()
				.unwrap()
				.then((data: any) => console.log(data, "Archive delete success"))
				.catch((err: any) => console.log(err, "Archive delete failed"))
		}
		deleteArchieve();
	}, [skip]);

	useEffect(() => {
		const showNewsAlert = async () => {
			dispatch(setSettingsModal({ newsAlertsModal: true }))
		}
		showNewsAlert();
	}, [skip])

	return (
		<div className="App">
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				limit={1}
			/>

			{isLoading && (
				<ProgressBar
					mode="indeterminate"
					style={{ height: '2px' }}
				></ProgressBar>
			)}
			{(devTokenLoaded.token.length > 0 || isTokenLoaded.length > 0) && (
				<div className={`xcharge flex flex-column lg:flex-row`}>
					<HashRouter
						basename={
							process.env.REACT_APP_ENV === 'staging' ? 'web/XCharge/' : ''
						}
					>
						{!(maintenanceMode && !isAdmin) && !isInvalidUser && <Navigation></Navigation>}
						<div
							className={`w-12 ${maintenanceMode && !isAdmin
								? 'w-full'
								: sidebar
									? 'lg:w-10'
									: 'lg:w-11'
								} pr-6 mt-3 pl-5`}
						>
							<SearchBar />
							<PathDebugger />
							{isRoleAssigned && <Main></Main>}
							{!skipIntro && triggerInfo && <CustomJoyride run={runJoyride} setRun={setRunJoyride}  />}
							{mailers.minimizeBugReport && <Button
								className="p-button-rounded p-button-text p-button-icon-only maximize-bug-report"
								tooltipOptions={{ position: 'top' }}
								tooltip="Maximize Bug Report"
								onClick={() => dispatch(setMailers({ minimizeBugReport: false, bugReportMailer: true }))}
							>
								<DisplayIcon icon="maximize" className="searchbar-button" />
							</Button>}
						</div>
					</HashRouter>
				</div>
			)}
			{/* Don't want this ReleaseDialog anymore
			{openModal.releaseNotes && <ReleaseDialog />} */}
			{<NewsAlertsDialog />}
		</div>
	);
}

export default App;
